import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ArticlesGrid from "../components/articles-grid"
import Seo from "../components/seo"
import BaseSection from "../components/base-section"
import BaseProse from "../components/base-prose"
import BasePagination from "../components/base-pagination"
import { byArticlesWithNoTestSlug } from "../utilities"

const BlogTemplate = ({ data, pageContext }) => {
  const SEO = {
    metaTitle: "Blog - Voltio",
    metaDescription:
      "Descubre lo que se mueve en nuestro Blog. Temas de actualidad, carsharing, movilidad sostenible, noticias, transporte Madrid.",
  }

  return (
    <Layout>
      <Seo seo={SEO} />

      <BaseSection>
        <BaseProse className="prose-2xl mx-auto mb-12 mt-4">
          <h1 className="text-5xl md:text-8xl">El Blog de Voltio</h1>
        </BaseProse>
        <ArticlesGrid
          articles={data.allStrapiArticle.nodes.filter(
            byArticlesWithNoTestSlug
          )}
        />

        <div className="mt-20">
          <BasePagination basePath="/blog" pageInfo={pageContext} />
        </div>
      </BaseSection>
    </Layout>
  )
}
export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allStrapiArticle(
      skip: $skip
      limit: $limit
      sort: { fields: [createdAt], order: DESC }
      filter: { locale: { eq: "es-ES" } }
    ) {
      nodes {
        ...ArticleCard
      }
    }
  }
`
export default BlogTemplate
